import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton'; // Import IconButton for close button
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon for close button icon
import SystemServices from 'services/System';
import { ErrorToaster, SuccessToaster } from 'components/Toaster';

const WhatsAppModal = ({ open, onClose, id }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleForward = async () => {
        try {
            if (!phoneNumber) {
                console.error('Phone number must be provided');
                return;
            }

            setLoading(true);

            const response = await SystemServices.forwardToPDFWhatsApp({
                customer_phone: phoneNumber,
                message: window.location.origin + (id)
            });

            console.log('WhatsApp API response:', response);

            setLoading(false);
            SuccessToaster(response?.message);
            onClose();
            setPhoneNumber('');
        } catch (error) {
            ErrorToaster(error);
            console.error('Error forwarding to WhatsApp:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            setPhoneNumber('');
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="forward-to-whatsapp-modal"
            aria-describedby="forward-to-whatsapp-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'action',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <h2 id="forward-to-whatsapp-modal">Invoice Forward To WhatsApp</h2>
                <TextField
                    id="phone-number"
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                    sx={{ mt: 2 }}
                />
                {loading ? (
                    <CircularProgress sx={{ mt: 2 }} />
                ) : (
                    <Button variant="contained" onClick={handleForward} sx={{ mt: 2 }}>
                        Forward
                    </Button>
                )}
            </Box>
        </Modal>
    );
};

export default WhatsAppModal;
